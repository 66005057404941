<template>
    <div>
        <div class="modal fade" id="vendorModal" tabindex="-1" aria-labelledby="vendorModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="vendorModalLabel" class="font-weight-bold">{{ModalTitle}}{{VendorCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CRow>
                            <CCol sm="2">
                                <label class="form-label font-weight-bold required">Kode</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CInput id="VendorCode" v-model="VendorCode" class="font-weight-bold" />
                                <label id="errorVendorCode" class="form-error" style="display: none; color: red;"></label>
                            </CCol>               
                        </CRow>
                        <CRow>
                             <CCol sm="2">
                                <label class="form-label font-weight-bold required">Nama</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CInput id="VendorName" v-model="VendorName" class="font-weight-bold" />
                                <label id="errorVendorName" class="form-error" style="display: none; color: red;"></label>
                            </CCol>    
                            <CCol sm="2">
                                <label class="form-label font-weight-bold">Status</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                            </CCol>                                     
                        </CRow>
                        <hr>

                        <CTabs>
                            <CTab title="Profile" id="tab-1" active>
                                <div class="p-3">
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Provinsi</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <v-select id="Province" class="pb-3" :options="ProvinceData" v-model="Province" @input="onChange()" />
                                            <label id="errorProvince" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Bebas Pajak</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="2">
                                            <div>
                                                <input type="checkbox" v-model="TaxFree"/> <label>Iya</label>
                                            </div>
                                            <label id="errorTaxFree" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Kota</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <v-select id="City" class="pb-3" :options="CityData" v-model="City"/>
                                            <label id="errorCity" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>    
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Negara</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Country" v-model="Country" class="font-weight-bold" readonly/>
                                            <label id="errorCountry" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>  
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">Alamat</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol> 
                                        <CCol sm="4" class="pb-3">
                                            <textarea class="form-control" rows="2" v-model="Address"></textarea>
                                            <label id="errorAddress" class="form-error pt-3" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Telp 1</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Phone1" v-model="Phone1" class="font-weight-bold" />
                                            <label id="errorPhone1" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Telp 2</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Phone2" v-model="Phone2" class="font-weight-bold" />
                                            <label id="errorPhone2" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Handphone 1</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="HandPhone1" v-model="HandPhone1" class="font-weight-bold" />
                                            <label id="errorHandPhone1" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                       <CCol sm="2">
                                            <label class="form-label font-weight-bold">Faks</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Fax" v-model="Fax" class="font-weight-bold" />
                                            <label id="errorFax" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">NPWP</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="NPWP" v-model="NPWP" class="font-weight-bold" />
                                            <label id="errorNPWP" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">PIC</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="PIC" v-model="PIC" class="font-weight-bold" />
                                            <label id="errorPIC" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Jabatan</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Position" v-model="Position" class="font-weight-bold" />
                                            <label id="errorPosition" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Catatan</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol> 
                                        <CCol sm="4" class="pb-3">
                                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                                            <label id="errorNote" class="form-error pt-3" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                </div>
                            </CTab>
                        </CTabs>
                        <br>
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vendorServices from '../Script/VendorServices';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'VendorForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',
            SaveType : '',
            Error: 0,
            ProfileError : 0,

            VendorId: '',
            VendorCode : '',
            VendorName : '',
            Status : '',
            StatusData: [],

            //Profile
            Country : '',
            Province : '',
            ProvinceData : [],
            TaxFree : '',
            City : '',
            CityData : [],
            Address : '',
            Phone1 : '',
            Phone2 : '',
            HandPhone1 : '',
            Fax : '',
            NPWP : '',
            PIC : '',
            Position : '',
            Note : '',

            //Shipping Address
            ShippingAddress : [],
        }
    },
    methods: {
        onChange(){
            this.City = '';
            this.Country = '';
            if (this.Province == null){
                this.CityData = [];
                this.Country = '';
            }
            else{
                this.CityData = this.$globalfunc.getCityData(this.Province.value);
                this.Country = this.$globalfunc.getCountryData(this.Province.value);
            }
        },
        addClick(){   
            $(".form-error").css("display", "none"); 
            $(".save-button").show(); 
            this.ModalTitle = 'Add Vendor: ';

            this.VendorCode = 'Auto';
            this.VendorName = '';
            
            this.StatusData = [{value: 'N', label: 'New'}];
            this.Status = this.StatusData[0].value;
           
            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == "DKI Jakarta");
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == "Jakarta Utara");
            this.Country = this.$globalfunc.getCountryData(this.Province.value);

            this.TaxFree = false;
            this.Address = '';
            this.Phone1 = '';
            this.Phone2 = '';
            this.HandPhone1 = '';
            this.Fax = '';
            this.NPWP = '';
            this.PIC = '';
            this.Position = '';
            this.Note = '';

            this.ShippingAddress = [];

            this.SaveType = 'Add';
            $('#tab-1').trigger('click');
            document.getElementById('VendorCode').readOnly = false;
            window.$('#vendorModal').modal('show');
        },
        async editClick(id, View) {
            var data = await vendorServices.getDetailContact(id);

            $(".form-error").css("display", "none");  
            $(".save-button").show();

            this.ModalTitle = 'Edit Vendor: ';
            this.VendorId = data.contact_id;
            this.VendorCode = data.contact_code;
            this.VendorName = data.contact_name;
            
            this.StatusData = [ {value: 'A', label: 'Active'},
                                {value: 'I', label: 'Inactive'}];
            this.Status = data.status=="N"?"A":data.status;
           
            var profileData = data.metadata.profile;

            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == profileData.province);
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == profileData.city);
            this.Country = this.$globalfunc.getCountryData(this.Province.value);

            this.TaxFree = profileData.taxfree;
            this.Address = profileData.address;
            this.Phone1 = profileData.phone1;
            this.Phone2 = profileData.phone2;
            this.HandPhone1 = profileData.handphone1;
            this.Fax = profileData.fax;
            this.NPWP = profileData.npwp;
            this.PIC = profileData.pic;
            this.Position = profileData.position;
            this.Note = profileData.note;

            //shipping address
            this.ShippingAddress = [];
            for (let i = 0; i < data.metadata.shipping_address.length; i++) {
                this.ShippingAddress.push(data.metadata.shipping_address[i]);
            }
            
            this.SaveType = 'Edit';
            $('#tab-1').trigger('click');
            document.getElementById('VendorCode').readOnly = true;
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Vendor: ';
            }

            window.$('#vendorModal').modal('show');
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.VendorCode == ''){
                this.errorShow('errorVendorCode');
            }
            if(this.VendorName == ''){
                this.errorShow('errorVendorName');
            }
            if(this.Province == '' || this.Province == null){
                this.errorShow('errorProvince');
            }
            if(this.City == '' || this.City == null){
                this.errorShow('errorCity');
            }
            if(this.Address == ''){
                this.errorShow('errorAddress');
            }
            if(this.NPWP == ''){
                this.errorShow('errorNPWP');
            }
        },
        async saveClick(){
            this.inputValidation();

            if(this.Error == 0){
                //shipping Address Array
                var shippingAddressArray = [];
                if(this.ShippingAddress.length < 1){
                    shippingAddressArray.push(this.Address);
                }else {
                    shippingAddressArray = this.ShippingAddress;
                }

                const profile = {
                    province : this.Province.value,
                    taxfree : this.TaxFree,
                    city :  this.City.value,
                    country : this.Country,
                    address : this.Address,
                    phone1 : this.Phone1,
                    phone2 : this.Phone2,
                    handphone1 : this.HandPhone1,
                    fax : this.Fax,
                    npwp : this.NPWP,
                    pic : this.PIC,
                    position : this.Position,
                    note : this.Note
                }

                const shipping_address = shippingAddressArray;

                const metadata = {
                    profile : profile,
                    shipping_address : shipping_address
                }

                const vendor = {
                    contact_code : this.VendorCode,
                    contact_type : "Vendor",
                    contact_name : this.VendorName,
                    status : this.Status,
                    metadata : metadata,
                    attachment1: null,
                    attachment2: null,
                }

                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : vendor
                    }

                    vendorServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#vendorModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.VendorId,
                        data : vendor
                    }

                    vendorServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#vendorModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }

            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>