import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class VendorService {
    readVendorQuery(){
        var query = `query($type:String, $status:String){
                        GetContact(ContactType:$type, ActiveFlag:$status){
                            contact_id
                            contact_code
                            contact_type
                            contact_name
                            status
                            metadata
                        }
                    }`;
        return query;
    }
    
    async getDetailContact(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetContact(ContactId:$id){
                created_at
                last_update
                contact_id
                contact_code
                contact_type
                contact_name
                status
                metadata
                attachment1
                attachment2
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetContact[0];
    }

    async addQuery(variables){
        var query = gql`mutation($data:NewContact!){
            CreateContact(contact:$data){
              response
            }
        }`;

        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!,$data:NewContact!){
            UpdateContact(contactID:$id,contact:$data){
              response
            }
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`mutation($id:Int!){
            DeleteContact(contactID:$id){
                response
            }
        }`;

        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    statusButtonGenerator(statusList){
        var start = '<div class="btn-group w-100">',
        main = '<button id="btnAll" style="width:12%" class="btn btn-secondary btn-All">All&nbsp;<b>'+'999'+'</b></button>&nbsp;',
        end = '</div><hr>';

        if(statusList != null){
            for (let i = 0; i < statusList.length; i++){
                var str = '<button id="btn' + statusList[i].toString().replace(/\s/g, '') + '" style="width:12%" class="btn btn-secondary btn-'+ statusList[i].toString().replace(/\s/g, '') +'" >' + statusList[i].toString() + '&nbsp;<b>'+ '999'+ '</b></button>&nbsp;';
                main = main + str;
            }
        }

        return (start + main + end);
    }
}

export default new VendorService();